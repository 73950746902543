import { useMemo, useState } from 'react';
import { functions } from '@wap-client/core';
import CategoryCard from '@/components/widgets/card/category-card';
import { Container } from '@/components/base/gridview';
import SliderCards from '@/components/base/slider-cards';
import { Tab, Tabs } from '@/components/base/tabs';
import SwiperToolbar from '@/components/base/slider-cards/slider-swiper/toolbar/SwiperToolbar';
import useDeviceType from '@/hooks/useDeviceType';
import { UICategorySlider, UICategorySliderCategory } from './types';
import Image from '@/components/base/image';

// const animations = {
//   container: {
//     hidden: {
//       opacity: 0,
//     },
//     show: {
//       opacity: 1,
//       transition: {
//         duration: 0.5,
//         staggerChildren: 0.15,
//         ease: [0.25, 1, 0.5, 1],
//       },
//     },
//   },
//   hero: {
//     hidden: {
//       opacity: 0,
//       x: -100,
//     },
//     show: {
//       opacity: 1,
//       x: 0,
//       transition: {
//         duration: 0.3,
//         ease: [0.25, 1, 0.5, 1],
//       },
//     },
//   },
//   cards: {
//     hidden: {
//       opacity: 0,
//     },
//     show: {
//       opacity: 1,
//       transition: {
//         duration: 0.3,
//         ease: [0.25, 1, 0.5, 1],
//       },
//     },
//   },
// };

const CategorySlider: React.FC<UICategorySlider & { id: string }> = ({
  id: componentId,
  categories = [],
  multipleShow,
  sliderToolbar,
  like,
}) => {
  const deviceType = useDeviceType();

  const initialCategoryKey = useMemo(
    () => categories[0]?.key || '',
    [categories]
  );
  const isMobileOrTabletDevice =
    deviceType === 'mobile' || deviceType === 'tablet';
  const [activeCategoryId, setActiveCategoryId] = useState(initialCategoryKey);

  const onChangeTab = (id: string) => {
    if (!categories) {
      return;
    }

    const category = categories.find((category) => category.key === id);
    if (category?.key) {
      setActiveCategoryId(id);
    }
  };

  const isCategoryActive = (category: UICategorySliderCategory) => {
    return category?.key === activeCategoryId;
  };

  const currentCategoryKey = useMemo(() => {
    // @TODO: this will be fix when the real data comes in. (unique id not valid)
    if (categories.length) {
      return `${componentId}-${
        categories[0]?.id || categories.length
      }-${activeCategoryId}`;
    }

    return `${componentId}-${
      categories[0]?.id || categories.length
    }-${activeCategoryId}`;
  }, [activeCategoryId]);

  if (isMobileOrTabletDevice) {
    return (
      <div className="category-slider">
        <Container size="fluid">
          <div className="category-slider-toolbar">
            <Tabs
              direction="row"
              align="left"
              activeId={activeCategoryId}
              onChange={onChangeTab}
            >
              {categories
                .filter(
                  (category) => category.items && category.items.length > 0
                )
                .map((category) => (
                  <Tab
                    id={category.key}
                    key={category.key}
                    className={functions.classnames(
                      category.tabImage && 'tabs2-tab--image'
                    )}
                  >
                    {category.tabImage ? (
                      <Image {...category.tabImage} alt={category.title} />
                    ) : (
                      category.title
                    )}
                  </Tab>
                ))}
            </Tabs>
          </div>
        </Container>
        <Container size="fluid">
          {categories.map((category) => (
            <div
              key={
                isCategoryActive(category)
                  ? `${category.key}-${activeCategoryId}`
                  : category.key
              }
              className={functions.classnames(
                'category-slider-container',
                isCategoryActive(category) && 'category-slider-container--show'
              )}
            >
              <div className={functions.classnames('category-slider-cards')}>
                <SliderCards
                  cards={category.items}
                  siderToolbarInsideShow={sliderToolbar !== false}
                  sliderId={
                    isCategoryActive(category)
                      ? currentCategoryKey
                      : `${activeCategoryId}-${category.key}`
                  }
                  sliderSettings={{
                    navigation: true,
                    pagination: {
                      dynamicBullets: (category.items as []).length > 6,
                      dynamicMainBullets: 3,
                    },
                    breakpoints: {
                      320: {
                        slidesPerView: 'auto',
                        spaceBetween: 8,
                      },
                      520: {
                        slidesPerView: 'auto',
                        spaceBetween: 20,
                      },
                      768: {
                        slidesPerView: 'auto',
                        spaceBetween: 20,
                      },
                      1025: {
                        slidesPerView: multipleShow ? 2 : 3,
                        spaceBetween: 20,
                        ...(multipleShow && {
                          slidesPerGroup: 2,
                          grid: {
                            rows: 2,
                            fill: 'row',
                          },
                        }),
                      },
                    },
                  }}
                />
              </div>
            </div>
          ))}
        </Container>
      </div>
    );
  }

  return (
    <div className="category-slider">
      <Container>
        <div className="category-slider-toolbar">
          <Tabs
            direction="row"
            align="left"
            activeId={activeCategoryId}
            onChange={onChangeTab}
          >
            {categories
              .filter((category) => category.items && category.items.length > 0)
              .map((category) => (
                <Tab
                  key={category.key}
                  id={category.key}
                  className={functions.classnames(
                    category.tabImage && 'tabs2-tab--image'
                  )}
                >
                  {category.tabImage ? (
                    <Image {...category.tabImage} alt={category.title} />
                  ) : (
                    category.title
                  )}
                </Tab>
              ))}
          </Tabs>
          {sliderToolbar !== false && (
            <SwiperToolbar
              key={currentCategoryKey}
              sliderId={currentCategoryKey}
            />
          )}
        </div>
        {categories.map((category) => {
          if (!Array.isArray(category?.items)) {
            return null;
          }

          return (
            <div
              key={
                isCategoryActive(category)
                  ? `${category.key}-${activeCategoryId}`
                  : category.key
              }
              className={functions.classnames(
                'category-slider-container',
                isCategoryActive(category) && 'category-slider-container--show',
                multipleShow && 'category-slider-container--big'
              )}
              // variants={animations.container}
              // initial="hidden"
              // whileInView="show"
              // viewport={{ once: true, amount: 0.35 }}
            >
              <div
                className={functions.classnames(
                  'category-slider-hero',
                  !multipleShow && 'category-slider-hero--small'
                )}
                // variants={animations.hero}
              >
                <CategoryCard
                  {...category}
                  key={category.key}
                  size={multipleShow ? 'big' : 'small'}
                />
              </div>
              <div
                className={functions.classnames(
                  'category-slider-cards',
                  !multipleShow && 'category-slider-hero--big'
                )}
                // variants={animations.cards}
              >
                <SliderCards
                  key={
                    isCategoryActive(category)
                      ? currentCategoryKey
                      : `${currentCategoryKey}-${category.key}`
                  }
                  cards={category.items || []}
                  like={like}
                  siderToolbarInsideShow={false}
                  sliderId={
                    isCategoryActive(category)
                      ? currentCategoryKey
                      : `${activeCategoryId}-${category.key}`
                  }
                  sliderSettings={{
                    navigation: true,
                    pagination: {
                      dynamicBullets: (category.items as []).length > 6,
                      dynamicMainBullets: 3,
                    },
                    breakpoints: {
                      320: {
                        slidesPerView: 'auto',
                        spaceBetween: 8,
                      },
                      520: {
                        slidesPerView: 'auto',
                        spaceBetween: 20,
                      },
                      768: {
                        slidesPerView: multipleShow ? 2 : 3,
                        spaceBetween: 20,
                        ...(multipleShow && {
                          slidesPerGroup: 2,
                          grid: {
                            rows: 2,
                            fill: 'row',
                          },
                        }),
                      },
                    },
                  }}
                />
              </div>
            </div>
          );
        })}
      </Container>
    </div>
  );
};

export default CategorySlider;
